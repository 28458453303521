<template>
  <div class="book-done">
    <van-image
      class="success-icon"
      :src="require('@/assets/success-icon.png')"
    />
    <div class="success-tip">购买成功</div>
    <van-cell-group>
      <van-cell title="订单号" :value="orderid || '-'" />
      <van-cell title="付款金额" :value="total" />
    </van-cell-group>
    <van-button type="primary" size="large" class="ok-btn" @click="handleDone">完成</van-button>
  </div>
</template>

<script>
import { checkPayResult } from '../api'
import { SESSION_NAME } from '../utils/login'
export default {
  name: 'book-done',
  data() {
    return {
      orderid: '',
      total: 0
    }
  },
  methods: {
    handleDone() {
      this.$router.push({ name: 'mine' })
    },
    getData() {
      checkPayResult({
        token: localStorage.getItem(SESSION_NAME),
        orderformid: this.$route.query.id
      }).then(res => {
        const { data } = res
        this.orderid = this.$route.query.id
        this.total = data
      })
    }
  },
  mounted() {
    this.getData()
  }
}
</script>

<style lang="scss" scoped>
.book-done {
  padding-top: 48px;

  .success-icon {
    width: 100px;
    height: 100px;
    display: block;
    margin: 0 auto;
  }

  .success-tip {
    font-size: 22px;
    text-align: center;
    margin-top: 10px;
    margin-bottom: 50px;
  }

  .ok-btn {
    display: block;
    width: 360px;
    margin: 40px auto;
  }
}
</style>
